<template>
  <div style="margin: 20px;">
    <div style="margin:1rem auto;">
     <!-- <a-input-number v-model="value8" :min="1" :max="10000" /> -->
     <el-input-number v-model="value8" :min="1" :max="10000"></el-input-number>
     <el-input style="width: 217px;margin-left:1rem;" v-model="input8" placeholder="请输入小班编号"></el-input>
     <el-button style="margin-left: 20px;" type="primary" @click="generateClick">生成</el-button>
    </div>
    <el-select v-model="treeId" placeholder="请选择" @change="getTreeId">
      <el-option
          v-for="item in treeList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
    <el-button style="margin-left: 20px;" type="primary" @click="remove">提交</el-button>
    <div style="height: 800px;overflow-y: auto;">
      <div class="chuzhi">
        <p>砍伐前：</p>
        <el-upload
            action="/plague/api/image/upload"
            list-type="picture-card"
            :headers="headers1"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>
      <div class="chuzhi">
        <p>砍伐后（分割库）：</p>
        <el-upload
            action="/plague/api/image/upload"
            list-type="picture-card"
            :headers="headers2"
            :on-preview="handlePictureCardPreview2"
            :on-remove="handleRemove2">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible2">
          <img width="100%" :src="dialogImageUrl2" alt="">
        </el-dialog>
      </div>
      <div class="chuzhi">
        <p>树桩分段前后：</p>
        <el-upload
            action="/plague/api/image/upload"
            list-type="picture-card"
            :headers="headers3"
            :on-preview="handlePictureCardPreview3"
            :on-remove="handleRemove3">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible3">
          <img width="100%" :src="dialogImageUrl3" alt="">
        </el-dialog>
      </div>
      <div class="chuzhi">
        <p>枝丫打包前后：</p>
        <el-upload
            action="/plague/api/image/upload"
            list-type="picture-card"
            :headers="headers4"
            :on-preview="handlePictureCardPreview4"
            :on-remove="handleRemove4">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible4">
          <img width="100%" :src="dialogImageUrl4" alt="">
        </el-dialog>
      </div>
      <div class="chuzhi">
        <p>树桩封装后：</p>
        <el-upload
            action="/plague/api/image/upload"
            list-type="picture-card"
            :headers="headers5"
            :on-preview="handlePictureCardPreview5"
            :on-remove="handleRemove5">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible5">
          <img width="100%" :src="dialogImageUrl5" alt="">
        </el-dialog>
      </div>
      <div class="chuzhi">
        <p>装车：</p>
        <el-upload
            action="/plague/api/image/upload"
            list-type="picture-card"
            :headers="headers6"
            :on-preview="handlePictureCardPreview6"
            :on-remove="handleRemove6">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible6">
          <img width="100%" :src="dialogImageUrl6" alt="">
        </el-dialog>
      </div>
      <div class="chuzhi">
        <p>卸车：</p>
        <el-upload
            action="/plague/api/image/upload"
            list-type="picture-card"
            :headers="headers7"
            :on-preview="handlePictureCardPreview7"
            :on-remove="handleRemove7">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible7">
          <img width="100%" :src="dialogImageUrl7" alt="">
        </el-dialog>
      </div>
      <div class="chuzhi">
        <p>粉碎前：</p>
        <el-upload
            action="/plague/api/image/upload"
            list-type="picture-card"
            :headers="headers8"
            :on-preview="handlePictureCardPreview8"
            :on-remove="handleRemove8">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible8">
          <img width="100%" :src="dialogImageUrl8" alt="">
        </el-dialog>
      </div>
      <div class="chuzhi">
        <p>粉碎前（粉碎库）：</p>
        <el-upload
            action="/plague/api/image/upload"
            list-type="picture-card"
            :headers="headers9"
            :on-preview="handlePictureCardPreview9"
            :on-remove="handleRemove9">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible9">
          <img width="100%" :src="dialogImageUrl9" alt="">
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "imageUpload",
  data(){
    return{
      value8: 1, //生成数量
      input8: '', //小班编号
      treeList:[],
      treeId:"",
      dialogImageUrl: '',
      dialogVisible: false,
      headers1:{
        imgType:"before",
        treeId:"",
        groupId:'4'
      },
      dialogImageUrl2: '',
      dialogVisible2: false,
      headers2:{
        imgType:"after",
        treeId:"",
        groupId:'4'
      },
      dialogImageUrl3: '',
      dialogVisible3: false,
      headers3:{
        imgType:"subsection",
        treeId:"",
        groupId:'4'
      },
      dialogImageUrl4: '',
      dialogVisible4: false,
      headers4:{
        imgType:"package",
        treeId:"",
        groupId:'4'
      },
      dialogImageUrl5: '',
      dialogVisible5: false,
      headers5:{
        imgType:"encapsulation",
        treeId:"",
        groupId:'4'
      },
      dialogImageUrl6: '',
      dialogVisible6: false,
      headers6:{
        imgType:"load",
        treeId:"",
        groupId:'4'
      },
      dialogImageUrl7: '',
      dialogVisible7: false,
      headers7:{
        imgType:"unload",
        treeId:"",
        groupId:'4'
      },
      dialogImageUrl8: '',
      dialogVisible8: false,
      headers8:{
        imgType:"pulverizer",
        treeId:"",
        groupId:'4'
      },
      dialogImageUrl9: '',
      dialogVisible9: false,
      headers9:{
        imgType:"smash",
        treeId:"",
        groupId:'4'
      },
    }
  },
  mounted() {
    this.getTreeList()
  },
  methods:{
    remove(){
      location.reload()
      this.getTreeList()
    },
    // 生成数据
    generateClick() {
      this.$axios.get(`/plague/api/elimination/addjr?count=${this.value8}&treeClass=${this.input8}`).then(res=>{
       console.log(res)
       if(res.code===200){
         this.$message.success('已生成')
        } else {
          this.$message.info('生成失败，请重试')
        }
      })
    },
    getTreeList(){
      this.treeId = ""
      this.$axios.get('/plague/api/elimination/queryjr').then(res=>{
        console.log(res);
        if(res.code===200){
          this.treeList = res.data.map(item=>{
            return{
              value:item,
              label:item
            }
          })
        }
      })
    },
    getTreeId(value){
      console.log(value);
      this.treeId = value
      this.headers1.treeId = value
      this.headers2.treeId = value
      this.headers3.treeId = value
      this.headers4.treeId = value
      this.headers5.treeId = value
      this.headers6.treeId = value
      this.headers7.treeId = value
      this.headers8.treeId = value
      this.headers9.treeId = value
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove2(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    },
    handleRemove4(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview4(file) {
      this.dialogImageUrl4 = file.url;
      this.dialogVisible4 = true;
    },
    handleRemove5(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview5(file) {
      this.dialogImageUrl5 = file.url;
      this.dialogVisible5 = true;
    },
    handleRemove6(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview6(file) {
      this.dialogImageUrl6 = file.url;
      this.dialogVisible6 = true;
    },
    handleRemove7(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview7(file) {
      this.dialogImageUrl7 = file.url;
      this.dialogVisible7 = true;
    },
    handleRemove8(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview8(file) {
      this.dialogImageUrl8 = file.url;
      this.dialogVisible8 = true;
    },
    handleRemove9(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview9(file) {
      this.dialogImageUrl9 = file.url;
      this.dialogVisible9 = true;
    },
    handleRemove3(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview3(file) {
      this.dialogImageUrl3 = file.url;
      this.dialogVisible3 = true;
    },
  }
}
</script>

<style scoped>
.el-input-number {
  width: 217px;
}
</style>
